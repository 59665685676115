import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { heights, colors } from '../../styles/variables'

export const StyledHeader = styled.header`
  height: ${heights.header}px;
  padding: 0 50px;
  background-color: ${colors.plain.nuances1};
  border-bottom: 2px solid ${colors.plain.nuances2};
`

export const HeaderInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const MobileLink = styled.div`
  z-index: 99;
  @media (min-width: 601px) {
    display: none;
  }
`

export const MenuButton = styled.button`
  background: transparent;
  color: ${colors.plain.nuances8};
  border: none;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MobileLinkWrapper = styled.div`
  background: ${colors.plain.nuances2};
  border-radius: 16px;
  position: absolute !important;
  right: 0;
  top: 60px;
  width: 234px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px;
  .inco-active-link {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 6px;
      left: 0;
      height: 4px;
      border-radius: 4px;
      background: linear-gradient(180deg, #db767c 0.01%, #a633c3 100%);
    }
  }
`

export const HomepageLink = styled(Link)`
  color: ${colors.plain.nuances8};
  text-transform: uppercase;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 30px;
  position: relative;
  display: flex;
  letter-spacing: 1px;
  @media (max-width: 600px) {
    margin-left: 0px;
    padding: 13px 0;
  }
  transition: 200ms;
  &:hover,
  &:focus {
    text-decoration: none;
    color: white;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  @media (max-width: 600px) {
    display: none;
  }
  margin-right: 50px;
  .inco-active-link {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -8px;
      left: 0;
      background-color: red;
      height: 4px;
      border-radius: 4px;
      background: linear-gradient(180deg, #db767c 0.01%, #a633c3 100%);
    }
  }
`
