import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { colors } from '../../styles/variables'
import Facebook from '../../assets/facebook.inline.svg'
import Twitter from '../../assets/twitter.inline.svg'
import Linkedin from '../../assets/linkedin.inline.svg'
import Instagram from '../../assets/instagram.inline.svg'

export const Container = styled.footer`
  border-top: 2px solid ${colors.plain.nuances3};
  background: ${colors.plain.nuances1Bis};
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  @media (max-width: 600px) {
    padding: 32px;
  }
`

export const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 600px) {
    flex-direction: column;
    padding-top: 24px;
  }
`

export const LeftColumn = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    align-self: flex-start;
  }
`

export const MiddleColumn = styled.div`
  color: ${colors.plain.pinkVeryLight};
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-self: center;
    align-self: flex-start;
    max-width: 100%;
    padding-top: 48px;
  }
`

export const MiddleText = styled.div`
  line-height: 27px;
  margin-bottom: 16px;
`

export const SocialWrapper = styled.div`
  @media (max-width: 600px) {
    padding-bottom: 48px;
    padding-top: 12px;
  }
`

export const SocialLink = styled.a`
  margin-right: 16px;
`

export const FacebookLink = styled(Facebook)``
export const LinkedinLink = styled(Linkedin)``
export const InstagramLink = styled(Instagram)``
export const TwitterLink = styled(Twitter)``

export const IncoLogo = styled(Img)`
  height: 40px;
  width: 160px;
  margin-right: 16px;
  border-radius: 3px;
  margin-left: -8px;
`

export const ChanelLogo = styled(Img)`
  height: 60px;
  width: 180px;
  border-radius: 3px;
`

export const LogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 0 8px 0;
  align-items: center;
`

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    align-self: flex-start;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

export const DirectionLink = styled(Link)`
  color: ${colors.plain.pinkVeryLight};
  text-transform: uppercase;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-left: 30px;
  &:hover {
    color: white;
    transition: 200ms;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
  @media (max-width: 600px) {
    margin-left: 0;
    margin-bottom: 24px;
  }
`
export const DirectionAnchor = styled.a`
  color: ${colors.plain.pinkVeryLight};
  text-transform: uppercase;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-left: 30px;

  &:hover,
  &:focus {
    text-decoration: none;
  }
  @media (max-width: 600px) {
    margin-left: 0;
    margin-bottom: 24px;
  }
`

export const BottomSection = styled.div`
  padding-top: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 600px) {
    flex-direction: column;
    padding-top: 0;
  }
`

export const BottomElement = styled.div`
  color: ${colors.plain.pinkVeryLight};
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0 16px;
  @media (max-width: 600px) {
    padding: 24px 0 0 0;
  }
`

export const BottomLink = styled.a`
  color: ${colors.plain.pinkVeryLight};
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0 16px;
  @media (max-width: 600px) {
    padding: 24px 0 0 0;
  }
`
