import React, { useContext, useState } from 'react'
import DataContext from '../Common/DataContext'
import Logo from '../../assets/logo.inline.svg'
import Close from '../../assets/close.inline.svg'
import { MenuButton, MobileLinkWrapper, StyledHeader, HeaderInner, HomepageLink, LinkWrapper, MobileLink } from './style'

const Header: React.FC = () => {
  const contextValue = useContext(DataContext)
  const { step } = contextValue
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const url = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <StyledHeader>
      <HeaderInner>
        <HomepageLink to="/" aria-label="Home">
          <Logo />
        </HomepageLink>
        <MobileLink>
          {mobileMenuOpen ? (
            <>
              <MenuButton onClick={() => setMobileMenuOpen(false)}>
                <Close />
              </MenuButton>
              <MobileLinkWrapper>
                <HomepageLink
                  to={step === 2 ? '/recommendations' : '/survey'}
                  partiallyActive
                  activeClassName="inco-active-link"
                  className={url.includes('/recommendations') ? 'inco-active-link' : ''}
                >
                  Recommendations
                </HomepageLink>
                <HomepageLink to="/content" activeClassName="inco-active-link" partiallyActive>
                  Content
                </HomepageLink>
              </MobileLinkWrapper>
            </>
          ) : (
            <MenuButton onClick={() => setMobileMenuOpen(true)}>Menu</MenuButton>
          )}
        </MobileLink>
        <LinkWrapper>
          <HomepageLink
            partiallyActive
            to={step === 2 ? '/recommendations' : '/survey'}
            activeClassName="inco-active-link"
            className={url.includes('/recommendations') ? 'inco-active-link' : ''}
          >
            Recommendations
          </HomepageLink>
          <HomepageLink partiallyActive to="/content" activeClassName="inco-active-link">
            Content
          </HomepageLink>
        </LinkWrapper>
      </HeaderInner>
    </StyledHeader>
  )
}

export default Header
