import React from 'react'
import Logo from '../../assets/footer-logo.inline.svg'
import getAsset from '../../services/getAsset'

import {
  Container,
  TopSection,
  LeftColumn,
  MiddleColumn,
  MiddleText,
  SocialWrapper,
  SocialLink,
  FacebookLink,
  LinkedinLink,
  InstagramLink,
  TwitterLink,
  RightColumn,
  DirectionLink,
  BottomSection,
  BottomElement,
  BottomLink,
  LinkWrapper,
  DirectionAnchor,
  IncoLogo,
  ChanelLogo,
  LogosWrapper
} from './style'

const Footer = () => {
  const chanel = getAsset('chanel-logo')
  const inco = getAsset('inco-logo')

  return (
    <Container>
      <TopSection>
        <LeftColumn>
          <Logo />
        </LeftColumn>
        <MiddleColumn>
          <MiddleText>
            Pow'Her In Tech is a global initiative led by Inco Group and supported by the Fondation Chanel
            <LogosWrapper>
              <a href="https://inco-group.co/" rel="noreferrer" aria-label="Inco Group" target="_blank">
                <IncoLogo fluid={inco?.childImageSharp?.fluid} />
              </a>
              <a href="http://www.fondationchanel.org/fr/" rel="noreferrer" target="_blank" aria-label="Fondation Chanel">
                <ChanelLogo fluid={chanel?.childImageSharp?.fluid} />
              </a>
            </LogosWrapper>
          </MiddleText>
          <SocialWrapper>
            <SocialLink rel="noreferrer" target="_blank" href="https://www.facebook.com/incogroupe/" aria-label="Inco Facebook">
              <FacebookLink />
            </SocialLink>
            <SocialLink rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/incogroup" aria-label="Inco Linkedin">
              <LinkedinLink />
            </SocialLink>
            <SocialLink rel="noreferrer" target="_blank" href="https://www.instagram.com/incogroup_/" aria-label="Inco Instagram">
              <InstagramLink />
            </SocialLink>
            <SocialLink rel="noreferrer" target="_blank" href="https://twitter.com/inco_group" aria-label="Inco Twitter">
              <TwitterLink />
            </SocialLink>
          </SocialWrapper>
        </MiddleColumn>
        <RightColumn>
          <LinkWrapper>
            <DirectionLink to="/recommendations">Recommendations</DirectionLink>
            <DirectionLink to="/content">Content</DirectionLink>
            <DirectionAnchor
              target="_blank"
              aria-label="Download guidelink"
              rel="noreferrer"
              href="http://france.academy.inco-group.co/assets/pocketguide.pdf"
              download
            >
              Download the guide
            </DirectionAnchor>
            <DirectionAnchor target="_blank" href="mailto:contact@inco.co.com">
              Contact
            </DirectionAnchor>
          </LinkWrapper>
        </RightColumn>
      </TopSection>
      <BottomSection>
        <BottomElement>© 2020 All rights reserved</BottomElement>
        <BottomLink target="_blank" aria-label="Terms and conditions" rel="noreferrer" href="https://inco-group.co/en/privacy-policy">
          Terms and Conditions
        </BottomLink>
      </BottomSection>
    </Container>
  )
}

export default Footer
