/* eslint-disable max-len */

export const colors = {
  white: '#fff',
  black: '#000',
  plain: {
    pinkVeryLight: '#F7F3FF',
    pinkLight: '#8E55B9',
    pinkDefault: '#662D91',
    pinkDark: '#270747',

    redVeryLight: '#FFF1F6',
    redLight: '#FC3C82',
    redDark: '#9E0B41',
    redDefault: '#FF71A5',

    nuances5: '#77707A',
    nuances4: '#4F4852',
    nuances3: '#3B343E',
    nuances2: '#27202A',
    nuances1Bis: '#1D1620',
    nuances1: '#18111B',
    nuances6: '#9F98A2',
    nuances7: '#C7C0CA',
    nuances8: '#EFE8F2',
    nuances9: '#FFFCFF',
    nuances10: '#FFFFFF',

    incoWhite: '#FBFAFC'
  },
  gradient: {
    pink: 'linear-gradient(180deg, #A86BBB 0%, #5D327D 100%)',
    red: 'linear-gradient(180deg, #F2656D 0.01%, #900DB0 100%)',
    nuances1: 'linear-gradient(180deg, #27202A 0%, #0C080E 100%)',
    nuances2: 'linear-gradient(180deg, #362F39 0%, #221B25 100%)',
    nuances3: 'linear-gradient(180deg, #77707A 0%, #4F4852 100%)',
    nuances4: 'linear-gradient(180deg, #9F98A2 0%, #4F4852 100%)',
    nuances6: 'linear-gradient(180deg, #EFE8F2 0%, #B3ACB6 100%)',
    nuances10: 'linear-gradient(180deg, #FFFFFF 0%, #EFE8F2 100%)'
  }
}

export const fonts = {
  primary: '"Source Sans Pro", sans-serif',
  secondary: '"Montserrat", sans-serif'
}

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140
}

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18
  },
  headingSizes: {
    h1: 36,
    h2: 24,
    h3: 24,
    h4: 24
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2
  },
  containerPadding: 1.5
}

export const heights = {
  header: 60
}
