/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'modern-normalize'
import '../styles/normalize'

import Header from '../components/Header'
import Footer from '../components/Footer'
import LayoutRoot from '../components/Common/LayoutRoot'
import LayoutMain from '../components/Common/LayoutMain'
import getAsset from '../services/getAsset'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
      author: string
      siteUrl: string
    }
  }
}

const IndexLayout: React.FC = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
              keywords
              author
              keywords
              siteUrl
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => {
        const { title, description, keywords, author, siteUrl } = data.site.siteMetadata
        const ogImage = getAsset('meta-image')
        const staticImg = `${siteUrl}${ogImage.childImageSharp.fluid.src}`
        const meta = [
          {
            name: `description`,
            content: description
          },
          {
            property: `og:title`,
            content: title
          },
          {
            property: `og:description`,
            content: description
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            property: 'og:logo',
            content: staticImg
          },
          {
            property: 'og:image',
            content: staticImg
          },
          {
            property: 'image',
            content: staticImg
          },
          {
            name: `twitter:card`,
            content: `summary`
          },
          {
            name: `twitter:creator`,
            content: author
          },
          {
            name: `twitter:title`,
            content: title
          },
          {
            name: `twitter:description`,
            content: description
          },
          {
            name: 'twitter:image',
            content: staticImg
          }
        ]
        return (
          <LayoutRoot>
            <Helmet
              htmlAttributes={{
                lang: 'en'
              }}
              link={[
                {
                  rel: 'canonical',
                  href: siteUrl
                }
              ]}
              meta={meta}
              title={data.site.siteMetadata.title}
              titleTemplate={title}
            >
              <meta charSet="utf-8" />
              <link
                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Press+Start+2P&family=Source+Sans+Pro&display=swap"
                rel="stylesheet"
              />
              {meta.map((elem, id) => (
                <meta key={id} name={elem.name} content={elem.content} />
              ))}
              <meta name="og:image" content={staticImg} />
              <meta name="robots" content="all" />
              <meta name="keywords" content={keywords} />
            </Helmet>
            <Header />
            <LayoutMain>{children}</LayoutMain>
            <Footer />
          </LayoutRoot>
        )
      }}
    />
  )
}

export default IndexLayout
